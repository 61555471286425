.tab1 {
  width: 100vw;
  height: 4.322917rem;
  background: url(../../assets/img/bg1.png) no-repeat left center/10rem 4.322917rem;
  background-color: #fff;
  padding: 1.265625rem 0 0 0;
}

.tab1 .title {
  font-size: 0.520833rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.729167rem;
  text-align: center;
}

.tab1 .hit {
  font-size: 0.1875rem;
  color: #FFFFFF;
  line-height: 0.260417rem;
  text-align: center;
  margin-top: 0.197917rem;
}