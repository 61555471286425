.tab4 {
  background: url(../../assets/img/footer.png) no-repeat center/100% 100%;
  width: 100vw;
  height: 0.78125rem;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: -0.010417rem;
}

.tab4 .hit {
  font-size: 0.072917rem;
  color: #FFFFFF;
  line-height: 0.104167rem;
  text-align: center;
  padding-bottom: 0.104167rem;
  font-weight: 2.083333rem;
}