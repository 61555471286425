.tab2 {
  text-align: center;
  padding: 0.416667rem 0 0.520833rem 0;
}

.tab2 .title {
  font-weight: 600;
  color: #333333;
  line-height: 0.291667rem;
  font-size: 0.208333rem;
}

.tab2 .hit {
  font-weight: 400;
  color: #333333;
  line-height: 0.166667rem;
  font-size: 0.125rem;
  margin-top: 0.104167rem;
}

.tab2 .list-wrapper {
  width: 6.25rem;
  height: 1.71875rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.03125rem 0 rgba(164,163,163,0.65);
  margin: 0.3125rem auto 0;
  padding: 0.3125rem 0.442708rem;
  display: flex;
  justify-content: space-between;
}

.tab2 .list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab2 .list .icon {
  width: 0.666667rem;
  height: 0.614583rem;
}

.tab2 .list .name {
  margin-top: 0.104167rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.21875rem;
  font-size: 0.15625rem;
}

.tab2 .list .info {
  margin-top: 0.041667rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.104167rem;
  font-size: 0.072917rem;
}

.tab2 .list:nth-child(1) .icon {
  background: url(../../assets/img/icon1.png) no-repeat center/100% 100%;
}

.tab2 .list:nth-child(2) .icon {
  background: url(../../assets/img/icon2.png) no-repeat center/100% 100%;
}

.tab2 .list:nth-child(3) .icon {
  background: url(../../assets/img/icon3.png) no-repeat center/100% 100%;
}

.tab2 .list:nth-child(4) .icon {
  background: url(../../assets/img/icon4.png) no-repeat center/100% 100%;
}