.tab1 {
  width: 100vw;
  height: 4.322917rem;
  background: url(../../static/media/bg1.26f16461.png) no-repeat left center/10rem 4.322917rem;
  background-color: #fff;
  padding: 1.265625rem 0 0 0;
}

.tab1 .title {
  font-size: 0.520833rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.729167rem;
  text-align: center;
}

.tab1 .hit {
  font-size: 0.1875rem;
  color: #FFFFFF;
  line-height: 0.260417rem;
  text-align: center;
  margin-top: 0.197917rem;
}
.tab2 {
  text-align: center;
  padding: 0.416667rem 0 0.520833rem 0;
}

.tab2 .title {
  font-weight: 600;
  color: #333333;
  line-height: 0.291667rem;
  font-size: 0.208333rem;
}

.tab2 .hit {
  font-weight: 400;
  color: #333333;
  line-height: 0.166667rem;
  font-size: 0.125rem;
  margin-top: 0.104167rem;
}

.tab2 .list-wrapper {
  width: 6.25rem;
  height: 1.71875rem;
  background: #FFFFFF;
  box-shadow: 0 0 0.03125rem 0 rgba(164,163,163,0.65);
  margin: 0.3125rem auto 0;
  padding: 0.3125rem 0.442708rem;
  display: flex;
  justify-content: space-between;
}

.tab2 .list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab2 .list .icon {
  width: 0.666667rem;
  height: 0.614583rem;
}

.tab2 .list .name {
  margin-top: 0.104167rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.21875rem;
  font-size: 0.15625rem;
}

.tab2 .list .info {
  margin-top: 0.041667rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.104167rem;
  font-size: 0.072917rem;
}

.tab2 .list:nth-child(1) .icon {
  background: url(../../static/media/icon1.f3f35a75.png) no-repeat center/100% 100%;
}

.tab2 .list:nth-child(2) .icon {
  background: url(../../static/media/icon2.ed95db9a.png) no-repeat center/100% 100%;
}

.tab2 .list:nth-child(3) .icon {
  background: url(../../static/media/icon3.2efbf8a1.png) no-repeat center/100% 100%;
}

.tab2 .list:nth-child(4) .icon {
  background: url(../../static/media/icon4.1b59f637.png) no-repeat center/100% 100%;
}
.tab3 {
  text-align: center;
  flex: 1 1;
  box-sizing: border-box;
}

.tab3 .yj {
  height: 100%;
  background: url(../../static/media/about.bb735d26.png) no-repeat center;
  background-size: cover;
  padding-left: 2.604167rem;
  box-sizing: border-box;
  padding-top: 2.604167rem;
  text-align: left;
}

.tab3 .yj :nth-child(1) {
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.291667rem;
  font-size: 0.208333rem;
}
.tab4 {
  background: url(../../static/media/footer.8640d803.png) no-repeat center/100% 100%;
  width: 100vw;
  height: 0.78125rem;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: -0.010417rem;
}

.tab4 .hit {
  font-size: 0.072917rem;
  color: #FFFFFF;
  line-height: 0.104167rem;
  text-align: center;
  padding-bottom: 0.104167rem;
  font-weight: 2.083333rem;
}
.box-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.appBar.MuiAppBar-root {
  height: 0.520833rem;
  background-color: #ffffff;
  box-shadow: none;
}

.appBar.MuiAppBar-root .MuiToolbar-root {
  background-color: #ffffff;
  height: 100%;
  min-height: 0.520833rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root.MuiToolbar-gutters {
  padding: 0;
  padding-right: 1.171875rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root .MuiTypography-root {
  display: flex;
  align-items: center;
  padding-left: 1.354167rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root .MuiTypography-root img {
  width: auto;
  height: 0.151042rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root>span {
  cursor: pointer;
  font-size: 0.072917rem;
  line-height: 0.104167rem;
  color: #123049;
  margin: 0 0.166667rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root>span.select {
  font-size: 0.072917rem;
  font-weight: 600;
  color: #009B93;
  line-height: 0.104167rem;
  position: relative;
}

.appBar.MuiAppBar-root .MuiToolbar-root .bar-tab {
  padding: 0 0.0625rem;
  height: 0.15625rem;
  background: #009B93;
  border-radius: 0.020833rem;
  font-size: 0.072917rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.104167rem;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.appBar.MuiAppBar-root .MuiToolbar-root .bar-tab .changelan-wrapper {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQoAAACgCAMAAADD5dLHAAAAAXNSR0IArs4c6QAAAP9QTFRFAAAA////////////////////6Oj/4/Hx6en06+vr4+zs5e7u5ubv4+rq5ubs4ufs5urq4ubq5Ojs4unp4+fq5Ofr4ujr4ufp5Obq4+fp4ujq4+fr5Ofq4+bp4+bp4+fq4+fp4+fq4ubq4+fq4ufq4+bq4+fp4ufq4ufp4+bp4+bp4ubq4+fq4+fp4ubp4+fq4+fq5Ojr5ejr5enr5ens5uns4ubp5enr5uns5urs5+vt6ezu6u3v6+7w7fDx7/Hz8fP08fP18vT19PX39vf49/j49/j59/n5+Pn6+fr6+vr7+vv7+/z8/Pz9/P39/f39/f3+/f7+/v7+/v//////K5y+WQAAADZ0Uk5TAAECAwQGCxIXGRseHyUpNT0+Q0dKS1lqe4CEiZWaprW8wMPK1tjg5ujr7e7z9Pn9/v7+/v7+qG9cXQAAAidJREFUeNrt1mlzz1AYhvHbvv5pY1+CIJYmSCy3LUWKKhHaeL7/Z/Gi7bTljRmmNXOu6wucM795ziLtZWcb3z0qkk4u+Llv7AdCh2558fsTX0JCV/1yipXmUZa8xHk3XyKi971jiUuceuD3ERHRuTiQtMTh234TERGx+tSXk6a45lc/1ilipWnOJCxxwe3X2GzJ948nK3H6oT/EVou+eTBRiSN3/HabRKw985U0JfZd9+vY0eemOZckxUU//raTIpa8cOL/2FyWl7V3r+ZT/Nqi96a6zLf/dmfFLq//7jeJWHvhPauYbUrMV277YYokm4a+dTW3MROVuzESbuxcrc9F4S4Sr3MhSZnbMXWKsXUmKXcfydc7l1R6gGJwKan2BMXkWpKNRIQNBRRQQAEFFFBAAQUUUEABBRRQQAEFFFBAAQUUUEABBRRQQAEFQQEFFFBAAQUUUEABBRRQQAEFFFBAAQUUUEABBRRQQAEFFFBAAQUUUEABBRT/gmL5YxIt/wHFtJpEEweEuwIKKKCAAgoooIACCiiggAIKKKCAAgoooIACCiiggAIKggIKKKCAAgoooIACCiiggAIKKKCAAgoooIACCiiggAIKKKCAAgoooIACCiig+EuK2hMSk2tJpQcoBpeScvdQ9M4lZW7H1CXG1pkkFe5Sp+hcSJJmlbuk52LsXM0kSZqv3PZDou/INPStqzltNCucdMVMW2V5WafJUJd5JtrZTypHtgT3VnSkAAAAAElFTkSuQmCC) no-repeat center/100% 100%;
  width: 1.213542rem;
  height: 0.4375rem;
  position: absolute;
  bottom: -0.026042rem;
  transform: translateY(100%) translateX(-50%);
  left: 50%;
  padding-top: 0.052083rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root .bar-tab .changelan-wrapper .changelanItem {
  height: 0.197917rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.0625rem;
  color: #123049;
  line-height: 0.072917rem;
  cursor: pointer;
  font-weight: 400;
}

.MuiToolbar-regular {
  min-height: 0.520833rem !important;
  height: 0.520833rem !important;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 0.072917rem;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd,
a {
  /* 默认有边距，都要清除 */
  margin: 0;
  padding: 0;
  /*字体设置*/
  font-size: 0.072917rem;
  font-family: "Microsoft Yahei",sans-serif;
  /* 去掉列表的原点 */
  list-style: none;
  /* 默认鼠标 */
  cursor: default;
}

/*可选*/

html,
body {
  width: 100%;
  height: 100%;
}

/*行内块元素*/

input,
img {
  margin: 0;
  padding: 0;
  border: 0 none;
  outline-style: none;
  vertical-align: bottom;
}

img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}

/*行内元素*/

a,
a:active,
a:visited {
  /*下划线和颜色*/
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

a:hover {
  color: #ffffff;
}

textarea {
  /* 边框清零 */
  border: none;
  /* 轮廓线清零 */
  outline: none;
  /* 防止文本域被随意拖拽 */
  resize: none;
}

i {
  /*文字样式*/
  font-style: normal;
}

table {
  /*边框合并*/
  border-collapse: collapse;
  border-spacing: 0;
}

/* 使用伪元素清除浮动 */

.clearfix::before,
.clearfix::after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: none;
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

/* 版心*/

.w {
  width: 6.25rem;
  margin: 0 auto;
}

/*可选*/

/*单选框和复选框的配置，一般是分开的*/

input[type="radio"],
input[type="checkbox"] {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  display: none;
}

label {
  display: inline-block;
  cursor: pointer;
}

label input[type="radio"]:checked~i,
label input[type="checkbox"]:checked~i {
  color: #38d6ff;
}

/*可选*/

/* 自定义数字框配置 */

input[type="number"] {
  width: 0.395833rem;
  height: 0.1875rem;
  background-color: rgba(5,45,82,0.4);
  border: 0.010417rem solid #ffffff;
  border-radius: 0.020833rem;
  color: #fff;
  font-size: 0.104167rem;
  padding: 0 0.052083rem;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"]+div {
  width: 0.15625rem;
  height: 0.208333rem;
  padding-left: 0.010417rem;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
}
