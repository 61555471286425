.tab3 {
  text-align: center;
  flex: 1 1;
  box-sizing: border-box;
}

.tab3 .yj {
  height: 100%;
  background: url(../../assets/img/about.png) no-repeat center;
  background-size: cover;
  padding-left: 2.604167rem;
  box-sizing: border-box;
  padding-top: 2.604167rem;
  text-align: left;
}

.tab3 .yj :nth-child(1) {
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.291667rem;
  font-size: 0.208333rem;
}