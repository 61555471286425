.appBar.MuiAppBar-root {
  height: 0.520833rem;
  background-color: #ffffff;
  box-shadow: none;
}

.appBar.MuiAppBar-root .MuiToolbar-root {
  background-color: #ffffff;
  height: 100%;
  min-height: 0.520833rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root.MuiToolbar-gutters {
  padding: 0;
  padding-right: 1.171875rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root .MuiTypography-root {
  display: flex;
  align-items: center;
  padding-left: 1.354167rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root .MuiTypography-root img {
  width: auto;
  height: 0.151042rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root>span {
  cursor: pointer;
  font-size: 0.072917rem;
  line-height: 0.104167rem;
  color: #123049;
  margin: 0 0.166667rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root>span.select {
  font-size: 0.072917rem;
  font-weight: 600;
  color: #009B93;
  line-height: 0.104167rem;
  position: relative;
}

.appBar.MuiAppBar-root .MuiToolbar-root .bar-tab {
  padding: 0 0.0625rem;
  height: 0.15625rem;
  background: #009B93;
  border-radius: 0.020833rem;
  font-size: 0.072917rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.104167rem;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.appBar.MuiAppBar-root .MuiToolbar-root .bar-tab .changelan-wrapper {
  background: url(../../assets/img/select-bg.png) no-repeat center/100% 100%;
  width: 1.213542rem;
  height: 0.4375rem;
  position: absolute;
  bottom: -0.026042rem;
  transform: translateY(100%) translateX(-50%);
  left: 50%;
  padding-top: 0.052083rem;
}

.appBar.MuiAppBar-root .MuiToolbar-root .bar-tab .changelan-wrapper .changelanItem {
  height: 0.197917rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.0625rem;
  color: #123049;
  line-height: 0.072917rem;
  cursor: pointer;
  font-weight: 400;
}

.MuiToolbar-regular {
  min-height: 0.520833rem !important;
  height: 0.520833rem !important;
}