* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 0.072917rem;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd,
a {
  /* 默认有边距，都要清除 */
  margin: 0;
  padding: 0;
  /*字体设置*/
  font-size: 0.072917rem;
  font-family: "Microsoft Yahei",sans-serif;
  /* 去掉列表的原点 */
  list-style: none;
  /* 默认鼠标 */
  cursor: default;
}

/*可选*/

html,
body {
  width: 100%;
  height: 100%;
}

/*行内块元素*/

input,
img {
  margin: 0;
  padding: 0;
  border: 0 none;
  outline-style: none;
  vertical-align: bottom;
}

img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}

/*行内元素*/

a,
a:active,
a:visited {
  /*下划线和颜色*/
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

a:hover {
  color: #ffffff;
}

textarea {
  /* 边框清零 */
  border: none;
  /* 轮廓线清零 */
  outline: none;
  /* 防止文本域被随意拖拽 */
  resize: none;
}

i {
  /*文字样式*/
  font-style: normal;
}

table {
  /*边框合并*/
  border-collapse: collapse;
  border-spacing: 0;
}

/* 使用伪元素清除浮动 */

.clearfix::before,
.clearfix::after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: none;
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

/* 版心*/

.w {
  width: 6.25rem;
  margin: 0 auto;
}

/*可选*/

/*单选框和复选框的配置，一般是分开的*/

input[type="radio"],
input[type="checkbox"] {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  display: none;
}

label {
  display: inline-block;
  cursor: pointer;
}

label input[type="radio"]:checked~i,
label input[type="checkbox"]:checked~i {
  color: #38d6ff;
}

/*可选*/

/* 自定义数字框配置 */

input[type="number"] {
  width: 0.395833rem;
  height: 0.1875rem;
  background-color: rgba(5,45,82,0.4);
  border: 0.010417rem solid #ffffff;
  border-radius: 0.020833rem;
  color: #fff;
  font-size: 0.104167rem;
  padding: 0 0.052083rem;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"]+div {
  width: 0.15625rem;
  height: 0.208333rem;
  padding-left: 0.010417rem;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
}